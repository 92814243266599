.loginOr {
  padding: 0.3em 0;
  text-align: center;
  font-size: 130%;
}

.loginWrap {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 2em;
  background: #fff;
  border-radius: 7px;
  border: 3px solid #f5f1e3;
}
.loginWrap .subMenuWrap {
  border-top: 1px dotted #cccccc;
  padding-top: 0.7em;
  margin-top: 1.7em;
  display: flex;
  flex-direction: column;
}
.loginWrap .signin * {
  color: #fff;
}
.loginWrap .signin a {
  width: 100%;
  padding: 0.7em 1em;
  font-size: 150%;
  text-align: center;
  display: block;
  background: linear-gradient(150deg, rgb(239, 57, 0) 0%, rgb(239, 57, 0) 19%, rgb(255, 124, 0) 39%, rgb(239, 57, 0) 58%, rgb(190, 0, 93) 75%, rgb(239, 57, 0) 100%);
}
.loginWrap .signin span {
  display: block;
}
.loginWrap .logoWrap {
  margin-bottom: 3em;
}
.loginWrap .formWrap h1 {
  margin-bottom: 1em;
}
.loginWrap .formWrap .notice {
  margin-bottom: 1em;
}
.loginWrap .formWrap .submitWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loginWrap .formWrap .submitWrap input[type=submit] {
  color: #fff;
  background-color: #ee7e22;
  outline: none;
  border: none;
  width: auto;
  margin-top: 0;
  margin-left: auto;
}
.loginWrap .formWrap .submitWrap input[type=submit]:hover {
  background-color: #df5200;
}
.loginWrap .formWrap .submitWrap button {
  color: #ee7e22;
  background-color: transparent;
  outline: none;
  border: none;
  width: auto;
  margin-top: 0;
  cursor: pointer;
}
.loginWrap .formWrap .submitWrap button:hover {
  color: #df5200;
}
.loginWrap .formWrap .errWrap {
  padding: 1.7em;
  margin-bottom: 0.7em;
}
.loginWrap .formWrap .errWrap > div {
  color: #dd0000;
  font-size: 16px;
  margin-bottom: 0.5em;
  display: flex;
  align-items: flex-start;
}
.loginWrap .formWrap .errWrap > div span {
  position: relative;
  display: inline-block;
  margin-top: 0.2em;
  margin-right: 1.3em;
  z-index: 1;
  text-align: center;
  color: #fff;
}
.loginWrap .formWrap .errWrap > div span::before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 30%;
  left: 50%;
  --b: 14px;
  width: 25px;
  transform: translate(-50%, -50%);
  --r: 0px;
  aspect-ratio: 1/0.8660254038;
  --_g: calc(tan(60deg)*var(--r)) bottom var(--r), #000 98%, #0000 101%;
  -webkit-mask: conic-gradient(from -30deg at 50% calc(200% - 3 * var(--r) / 2), #000 60deg, rgba(0, 0, 0, 0) 0) 0 100%/100% calc(100% - 3 * var(--r) / 2) no-repeat, radial-gradient(var(--r) at 50% calc(2 * var(--r)), #000 98%, rgba(0, 0, 0, 0) 101%), radial-gradient(var(--r) at left var(--_g)), radial-gradient(var(--r) at right var(--_g));
  -webkit-clip-path: polygon(50% 0, 100% 100%, 0 100%);
          clip-path: polygon(50% 0, 100% 100%, 0 100%);
  background: linear-gradient(45deg, #FA6900, #C02942);
}
.loginWrap .formWrap .errWrap > div:last-of-type {
  margin-bottom: 0;
}
.loginWrap .formWrap .inputWrap {
  position: relative;
  margin-bottom: 1.3em;
}
.loginWrap .formWrap .inputWrap.error input[type=text],
.loginWrap .formWrap .inputWrap.error input[type=password] {
  outline: 2px solid #dd0000;
}
.loginWrap .formWrap .inputWrap > span {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  z-index: 10;
}
.loginWrap .formWrap .inputWrap input[type=text],
.loginWrap .formWrap .inputWrap input[type=password],
.loginWrap .formWrap .inputWrap .placeholder {
  border-radius: 3px;
  transition: 0.2s;
}
.loginWrap .formWrap .inputWrap input[type=text],
.loginWrap .formWrap .inputWrap input[type=password] {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 1em;
  background-color: transparent;
  border: none;
  outline: 1px solid #cccccc;
}
.loginWrap .formWrap .inputWrap input[type=text]:focus,
.loginWrap .formWrap .inputWrap input[type=password]:focus {
  outline: 2px solid #ee7e22;
}
.loginWrap .formWrap .inputWrap input[type=text]:not(:-moz-placeholder-shown) + *, .loginWrap .formWrap .inputWrap input[type=password]:not(:-moz-placeholder-shown) + * {
  top: 0;
  background: #fff;
  z-index: 2;
}
.loginWrap .formWrap .inputWrap input[type=text]:focus + *, .loginWrap .formWrap .inputWrap input[type=text]:not(:placeholder-shown) + *,
.loginWrap .formWrap .inputWrap input[type=password]:focus + *,
.loginWrap .formWrap .inputWrap input[type=password]:not(:placeholder-shown) + * {
  top: 0;
  background: #fff;
  z-index: 2;
}
.loginWrap .formWrap .inputWrap .placeholder {
  position: absolute;
  z-index: 0;
  left: 1em;
  top: 50%;
  color: #999999;
  padding: 0 0.5em;
  transform: translate(0, -50%);
}
.loginWrap .formWrap .optinWrap {
  margin-bottom: 1.3em;
}
.loginWrap .formWrap .optinWrap .docs {
  margin-bottom: 1.3em;
}
.loginWrap form {
  margin-bottom: 3em;
}
.loginWrap form:last-of-type {
  margin-bottom: 0;
}
.loginWrap form select,
.loginWrap form textarea,
.loginWrap form input {
  padding: 0.6em 0.6em;
  border-radius: 3px;
  border: 1px solid #cccccc;
}
.loginWrap form input[type=text] {
  width: 100%;
}
.loginWrap form > input[type=submit] {
  margin-top: 1em;
}
.loginWrap form input[type=submit] {
  width: 100%;
  background: #ee7e22;
  color: #fff;
}
.loginWrap form input[type=submit]:hover {
  background: #df5200;
}

.loginWrap .selectionWrap {
  padding-top: 1.6em;
}
.loginWrap .loginBorder {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2em 0;
  /* padding: 30px 0; */
  font-size: 16px;
  font-weight: bold;
  color: #838383;
}
.loginWrap .loginBorder:before, .loginWrap .loginBorder:after {
  content: "";
  height: 3px;
  flex-grow: 1;
  border-radius: 10px;
  background-color: #d7d7d7;
}
.loginWrap .loginBorder:before {
  margin-right: 1em;
}
.loginWrap .loginBorder:after {
  margin-left: 1em;
}
.loginWrap .buttonWrap button {
  width: 100%;
  border: none;
  background: #fff;
  cursor: pointer;
}
.loginWrap .buttonWrap button:hover {
  filter: brightness(0.97);
}
.loginWrap .buttonWrap .button {
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  padding: 0.7em 1.3em;
  display: flex;
  align-items: center;
  border-radius: 3px;
  margin-bottom: 1.3em;
  justify-content: space-between;
}
.loginWrap .buttonWrap .button > * {
  line-height: 1.3;
}
.loginWrap .buttonWrap .button:last-of-type {
  margin-bottom: 0;
}
.loginWrap .buttonWrap .button .image {
  display: flex;
  align-items: center;
  max-width: 45px;
  margin-right: 1.4em;
  padding-right: 1.4em;
  border-right: 1px solid rgba(0, 0, 0, 0.09);
}
.loginWrap .buttonWrap .button .image img {
  width: 100%;
}
.loginWrap .buttonWrap .button .text {
  width: 100%;
  font-size: 14px;
}
.loginWrap .buttonWrap .button.line {
  background-color: #06C755;
}
.loginWrap .buttonWrap .button.line > * {
  color: #fff;
}
.loginWrap .buttonWrap .button.google {
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.loginWrap .buttonWrap .button.apple {
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.loginWrap .buttonWrap .button.amazon {
  background: rgb(255, 233, 172);
  background: linear-gradient(180deg, rgb(255, 233, 172) 0%, rgb(250, 215, 119) 50%, rgb(245, 197, 67) 100%);
}
.loginWrap .buttonWrap .button.smares {
  background-color: #6dace2;
}
.loginWrap .buttonWrap .button.smares > * {
  color: #fff;
}
.loginWrap .buttonWrap .button.smares span {
  color: yellow;
  font-size: 12px;
}
.textareaWrap {
  margin: 10px;
}
.textareaWrap .buttonWrap {
  text-align: center;
  padding: 0.3em 1em;
  margin-top: 0.7em;
  font-size: 170%;
  border-radius: 50px;
  color: #fff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  background: linear-gradient(
    150deg,
    rgb(239, 57, 0) 0%,
    rgb(239, 57, 0) 19%,
    rgb(255, 124, 0) 39%,
    rgb(239, 57, 0) 58%,
    rgb(190, 0, 93) 75%,
    rgb(239, 57, 0) 100%
  );
}