@charset "UTF-8";
/* クーポン画像部分の高さ決め打ち */
* {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 400;
  font-style: normal;
  max-width: 100%;
}

.mainMenu,
body {
  background: #f7f6e9;
  background-image: url(/public/images/bgBody.jpg);
}

.logoWrap {
  position: relative;
  background: #fff;
  z-index: 10;
  padding: 13px 10px 7px 10px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  padding-right: 55px;
}

.logoWrap img {
  width: 100%;
  max-width: 130px;
}

.logoWrap .login a {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.logoWrap .login img {
  width: 100%;
  max-width: 30px;
  padding-right: 0.3em;
}

.mypageWrap {
  padding-bottom: 67px;
}
.mypageWrap a {
  color: #333333;
  text-decoration: none;
}
.mypageWrap .commTitle {
  font-size: 120%;
  margin-bottom: 0.7em;
}
.mypageWrap .commSpacer {
  padding: 0 1em;
}
.mypageWrap .commMore {
  position: absolute;
  bottom: -2em;
  right: 1em;
}
.mypageWrap .commMore a {
  font-size: 12px;
}
/* .mypageWrap .commTextbody {
  padding: 1em 0;
} */
.mypageWrap .icoCoupon {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mypageWrap .icoCoupon:before {
  content: "";
  width: 1.7em;
  height: 1.7em;
  margin-right: 0.3em;
  background-image: url(/public/images/icon/icoCoupon.svg);
}
.mypageWrap .icoNews {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.mypageWrap .icoNews:before {
  content: "";
  width: 1.7em;
  height: 1.7em;
  margin-right: 0.3em;
  background-repeat: no-repeat;
  background-image: url(/public/images/icon/icoInfo.svg);
  background-position: center center;
} 
.mypageWrap .icoMember {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mypageWrap .icoMember:before {
  content: "";
  width: 1.7em;
  height: 1.7em;
  margin-right: 0.3em;
  background-image: url(/public/images/icon/icoMypage.svg);
}
.mypageWrap .topicsWrap {
  position: relative;
  z-index: 0;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.mypageWrap .topicsWrap .swiper-pagination {
  bottom: 0px;
}
.mypageWrap .topicsWrap .swiper-pagination-bullet-active {
  background: #333;
}
.mypageWrap .newsWrap {
  position: relative;
  margin-bottom: 5em;
}
.mypageWrap .newsWrap.single {
  padding-top: 2em;
}
.mypageWrap .newsWrap .items {
  margin-bottom: 0.3em;
}
.mypageWrap .newsWrap .items:last-of-type {
  margin-bottom: 0;
}
.mypageWrap .newsWrap .items a {
  display: block;
  width: 100%;
  padding: 0.2em 0;
  border-bottom: 1px solid #d5d5d5;
}
.mypageWrap .newsWrap .shortTitle a {
  white-space: nowrap;         /* テキストを一行で表示 */
  overflow: hidden;            /* オーバーフローを隠す */
  text-overflow: ellipsis;     /* 省略記号（...）を表示 */
  width: 100%;                 /* 親要素の幅に合わせる */
  box-sizing: border-box;      /* パディングとボーダーを含めたサイズを指定 */
}
.mypageWrap .couponWrap {
  position: relative;
  margin-bottom: 5em;
}
.mypageWrap .couponWrap.single {
  padding-top: 2em;
}
.mypageWrap .couponWrap .coupons {
  display: flex;
  flex-wrap: wrap;
}
.mypageWrap .couponWrap .coupons > div {
  width: calc(50% - 0.25em);
  margin-right: 0.5em;
  margin-bottom: 0.5em;
}
.mypageWrap .couponWrap .coupons > div:nth-of-type(2n) {
  margin-right: 0;
}
.mypageWrap .couponWrap .coupons.single > div {
  width: 100%;
  margin-right: 0;
  margin-bottom: 0;
}
.mypageWrap .couponWrap .coupons.single .top {
  height: 385px !important;
}
.mypageWrap .couponWrap .coupons.single .top .name {
  font-size: 16px !important;
}
.mypageWrap .couponWrap .coupons.single .limit {
  font-size: 130%;
}
.mypageWrap .couponWrap .coupons .couponInfo {
  text-align: center;
  padding: 0.3em 1em;
  margin-top: 0.7em;
  font-size: 170%;
  border-radius: 50px;
  color: #fff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  background: linear-gradient(
    150deg,
    rgb(239, 57, 0) 0%,
    rgb(239, 57, 0) 19%,
    rgb(255, 124, 0) 39%,
    rgb(239, 57, 0) 58%,
    rgb(190, 0, 93) 75%,
    rgb(239, 57, 0) 100%
  );
}
.mypageWrap .couponWrap .coupons .items .coupon {
  position: relative;
  width: 100%;
  background: #d54700;
  background: linear-gradient(220.55deg, #f50000 0%, #7d1717 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.mypageWrap .couponWrap .coupons .items .coupon .memberOnly {
  position: absolute;
  top: -2.3em;
  right: -4.5em;
  color: #fff;
  z-index: 10;
  transform: rotate(45deg);
  background: red;
  text-align: center;
  width: 10em;
  padding: 3.3em 0 0.3em 0;
  line-height: 1;
  transform-origin: center center;
}

.mypageWrap .couponWrap .coupons .items:nth-of-type(2n) .coupon {
  background: linear-gradient(220.55deg, #f50000 0%, #7d1717 100%);
}
.mypageWrap .couponWrap .coupons .items .top {
  width: 100%;
  position: relative;
  background: #fffbef;
  height: 185px;
  z-index: 1;
}
.mypageWrap .couponWrap .coupons .items .top img {
  position: absolute;
  vertical-align: bottom;
  top: 50%;
  left: 50%;
  width: 102%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.mypageWrap .couponWrap .coupons .items .top:before {
  content: "";
  position: absolute;
  bottom: -17px;
  left: 0;
  width: 17px;
  height: 17px;
  background: #fefaee;
  border-radius: 50px;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.mypageWrap .couponWrap .coupons .items .top:after {
  content: "";
  position: absolute;
  bottom: -17px;
  right: -17px;
  width: 17px;
  height: 17px;
  background: #fefaee;
  border-radius: 50px;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.mypageWrap .couponWrap .coupons .items .top .name {
  position: absolute;
  width: 95%;
  padding: 0 0.3em;
  text-align: center;
  bottom: 0.7em;
  left: 50%;
  transform: translate(-50%, 0%);
  font-size: 12px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
  display: inline-block;
}
.mypageWrap .couponWrap .coupons .items .top .image {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}
.mypageWrap .couponWrap .coupons .items .bottom {
  position: relative;
  width: 100%;
  padding: 0.7em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}
.mypageWrap .couponWrap .coupons .items .bottom:before {
  content: "";
  width: 100%;
  height: 100%;
  border-top: 4px dotted #fefaee;
  position: absolute;
  top: -2px;
  left: -2px;
}
.mypageWrap .couponWrap .coupons .items .bottom .price {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.3em;
  padding: 0.7em 0;
}
.mypageWrap .couponWrap .coupons .items .bottom .price * {
  color: #fff;
  line-height: 1;
}
.mypageWrap .couponWrap .coupons .items .bottom .price .before {
  font-size: 130%;
  font-weight: 800;
  position: relative;
  margin-right: 0.3em;
}
.mypageWrap .couponWrap .coupons .items .bottom .price .before:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -7%;
  background: #000;
  width: 100%;
  height: 3px;
  transform: translate(0, -50%) rotate(-10deg);
  opacity: 0.75;
}
.mypageWrap .couponWrap .coupons .items .bottom .price .after {
  font-size: 200%;
  font-weight: 800;
}
.mypageWrap .couponWrap .coupons .items .bottom .price span {
  font-size: 10px;
}
.mypageWrap .couponWrap .coupons .items .bottom .date {
  color: #fff;
  font-size: 10px;
}
.mypageWrap .couponWrap .coupons .items .coupon .limit {
  position: absolute;
  top: 0;
  left: 0.5em;
  z-index: 3;
  color: #fff;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}
.mypageWrap .couponWrap .coupons .items .coupon .limit * {
  font-size: 200%;
  color: #fff;
  font-weight: bold;
}
.mypageWrap .couponWrap .couponNotice {
  margin-top: 1.7em;
}

.mypageWrap .couponcodeWrap .useCoupon {
  padding: 1em;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mypageWrap .couponcodeWrap .useCoupon button {
  text-align: center;
  display: block;
  margin-top: 1em;
  font-size: 130%;
  color: #fff;
  padding: 0.3em 1em;
  margin-bottom: 0.3em;
  border-radius: 100px;
  background: linear-gradient(150deg, rgb(239, 57, 0) 0%, rgb(239, 57, 0) 19%, rgb(255, 124, 0) 39%, rgb(239, 57, 0) 58%, rgb(190, 0, 93) 75%, rgb(239, 57, 0) 100%);
}

.mypageWrap .couponcodeWrap .useCouponSubmit button {
  margin-top: 0.7em;
  padding: 0.3em 1em;
  color: #000;
}

.mypageWrap .couponcodeWrap {
  border: 3px dotted #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mypageWrap .couponcodeWrap * {
  color: #fff;
}
.mypageWrap .couponcodeWrap .msg1 {
  font-size: 200%;
  font-weight: 800;
  margin-bottom: 0.7em;
}
.mypageWrap .couponcodeWrap .msg2 {
  font-size: 130%;
}
.mypageWrap .couponcodeWrap .code {
  line-height: 1;
  font-size: 500%;
  font-weight: 800;
  margin-bottom: 0.3em;
}
.mypageWrap .offerWrap.single {
  padding-top: 2em;
}
.mypageWrap .offerWrap .offer * {
  color: #fff;
}
.mypageWrap .offerWrap .offer a {
  margin: 20px 0;
  width: 100%;
  padding: 0.7em 1em;
  font-size: 150%;
  text-align: center;
  display: block;
  background: linear-gradient(
    150deg,
    rgb(239, 57, 0) 0%,
    rgb(239, 57, 0) 19%,
    rgb(255, 124, 0) 39%,
    rgb(239, 57, 0) 58%,
    rgb(190, 0, 93) 75%,
    rgb(239, 57, 0) 100%
  );
}
.mypageWrap .offerWrap .offer a span {
  display: block;
}
.mypageWrap .loginCont.single {
  padding-top: 2em;
}
.mypageWrap .adWrap {
  position: relative;
  margin-bottom: 5em;
}
.mypageWrap .adWrap .items {
  margin-bottom: 0.7em;
}
.mypageWrap .adWrap .items:last-of-type {
  margin-bottom: 0;
}

.menuWrap {
  position: relative;
  width: 100%;
  position: fixed;
  background: #fff;
  z-index: 10;
  bottom: 0;
  left: 0;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.menuWrap a {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 60px;
  height: 60px;
}
.menuWrap a span {
  color: black;
  position: absolute;
  width: 100%;
  font-size: 10px;
  text-align: center;
  bottom: 0.5em;
  left: 50%;
  transform: translate(-50%, 0%);
}
.menuWrap img {
  width: 100%;
  max-width: 37px;
  padding-bottom: 0.8em;
}
.menuWrap a.current {
  background: rgb(239, 57, 0);
  background: linear-gradient(
    150deg,
    rgb(239, 57, 0) 0%,
    rgb(239, 57, 0) 19%,
    rgb(255, 124, 0) 39%,
    rgb(239, 57, 0) 58%,
    rgb(190, 0, 93) 75%,
    rgb(239, 57, 0) 100%
  );
  bottom: 10px;
}
.menuWrap a.current img {
  filter: brightness(0) invert(1);
}
.menuWrap a.current span {
  color: #fff;
}

.swiper-button-next,
.swiper-button-prev {
  color: #333 !important;
}

.okagemaru {
  position: relative;
  animation: okagemaru;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.modalTopReview {
  background: linear-gradient(220.55deg, #FFED46 0%, #FF7EC7 100%);
  color: #fff;
  cursor: pointer;
  text-align: center;
  padding: 0.3em 0;
}

@keyframes okagemaru {
  0% {
    top: -200px;
    right: 300px;
    opacity: 1;
  }
  20% {
    top: -150px;
    right: 330px;
    opacity: 1;
  }
  40% {
    top: -100px;
    right: 360px;
    opacity: 1;
  }
  60% {
    top: -100px;
    right: 390px;
    opacity: 1;
  }
  100% {
    top: 200px;
    right: 430px;
    opacity: 0;
    display: none;
  }
}

/* 会員情報変更画面 */
.loginOr {
  padding: 0.3em 0;
  text-align: center;
  font-size: 130%;
}

.memberInfoWrap {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  padding: 2em;
  background: #fff;
  border-radius: 7px;
  border: 3px solid #f5f1e3;
}
/* .memberInfoWrap .subMenuWrap {
  border-top: 1px dotted #cccccc;
  padding-top: 0.7em;
  margin-top: 1.7em;
  display: flex;
  flex-direction: column;
} */
/* .memberInfoWrap .signin * {
  color: #fff;
}
.memberInfoWrap .signin a {
  width: 100%;
  padding: 0.7em 1em;
  font-size: 150%;
  text-align: center;
  display: block;
  background: linear-gradient(150deg, rgb(239, 57, 0) 0%, rgb(239, 57, 0) 19%, rgb(255, 124, 0) 39%, rgb(239, 57, 0) 58%, rgb(190, 0, 93) 75%, rgb(239, 57, 0) 100%);
}
.memberInfoWrap .signin span {
  display: block;
} */
.memberInfoWrap .logoWrap {
  margin-bottom: 3em;
}
.memberInfoWrap .formWrap h1 {
  margin-bottom: 1em;
}
.memberInfoWrap .formWrap .notice {
  margin-bottom: 1em;
}
.memberInfoWrap .formWrap .submitWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.memberInfoWrap .formWrap .submitWrap input[type=submit] {
  color: #fff;
  background-color: #ee7e22;
  outline: none;
  border: none;
  width: auto;
  margin-top: 0;
  margin-left: auto;
}
.memberInfoWrap .formWrap .submitWrap input[type=submit]:hover {
  background-color: #df5200;
}
.memberInfoWrap .formWrap .submitWrap button {
  color: #ee7e22;
  background-color: transparent;
  outline: none;
  border: none;
  width: auto;
  margin-top: 0;
  cursor: pointer;
}
.memberInfoWrap .formWrap .submitWrap button:hover {
  color: #df5200;
}
.memberInfoWrap .formWrap .errWrap {
  padding: 1.7em;
  margin-bottom: 0.7em;
}
.memberInfoWrap .formWrap .errWrap > div {
  color: #dd0000;
  font-size: 16px;
  margin-bottom: 0.5em;
  display: flex;
  align-items: flex-start;
}
.memberInfoWrap .formWrap .errWrap > div span {
  position: relative;
  display: inline-block;
  margin-top: 0.2em;
  margin-right: 1.3em;
  z-index: 1;
  text-align: center;
  color: #fff;
}
.memberInfoWrap .formWrap .errWrap > div span::before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 30%;
  left: 50%;
  --b: 14px;
  width: 25px;
  transform: translate(-50%, -50%);
  --r: 0px;
  aspect-ratio: 1/0.8660254038;
  --_g: calc(tan(60deg)*var(--r)) bottom var(--r), #000 98%, #0000 101%;
  -webkit-mask: conic-gradient(from -30deg at 50% calc(200% - 3 * var(--r) / 2), #000 60deg, rgba(0, 0, 0, 0) 0) 0 100%/100% calc(100% - 3 * var(--r) / 2) no-repeat, radial-gradient(var(--r) at 50% calc(2 * var(--r)), #000 98%, rgba(0, 0, 0, 0) 101%), radial-gradient(var(--r) at left var(--_g)), radial-gradient(var(--r) at right var(--_g));
  -webkit-clip-path: polygon(50% 0, 100% 100%, 0 100%);
          clip-path: polygon(50% 0, 100% 100%, 0 100%);
  background: linear-gradient(45deg, #FA6900, #C02942);
}
.memberInfoWrap .formWrap .errWrap > div:last-of-type {
  margin-bottom: 0;
}
.memberInfoWrap .formWrap .inputWrap {
  position: relative;
  margin-bottom: 1.3em;
}
.memberInfoWrap .formWrap .inputWrap.error input[type=text],
.memberInfoWrap .formWrap .inputWrap.error input[type=password] {
  outline: 2px solid #dd0000;
}
.memberInfoWrap .formWrap .inputWrap > span {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 0;
  z-index: 10;
}
.memberInfoWrap .formWrap .inputWrap input[type=text],
.memberInfoWrap .formWrap .inputWrap input[type=password],
.memberInfoWrap .formWrap .inputWrap .placeholder {
  border-radius: 3px;
  transition: 0.2s;
}
.memberInfoWrap .formWrap .inputWrap input[type=text],
.memberInfoWrap .formWrap .inputWrap input[type=password] {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 1em;
  background-color: transparent;
  border: none;
  outline: 1px solid #cccccc;
}
.memberInfoWrap .formWrap .inputWrap input[type=text]:focus,
.memberInfoWrap .formWrap .inputWrap input[type=password]:focus {
  outline: 2px solid #ee7e22;
}
.memberInfoWrap .formWrap .inputWrap input[type=text]:not(:-moz-placeholder-shown) + *, .memberInfoWrap .formWrap .inputWrap input[type=password]:not(:-moz-placeholder-shown) + * {
  top: 0;
  background: #fff;
  z-index: 2;
}
.memberInfoWrap .formWrap .inputWrap input[type=text]:focus + *, .memberInfoWrap .formWrap .inputWrap input[type=text]:not(:placeholder-shown) + *,
.memberInfoWrap .formWrap .inputWrap input[type=password]:focus + *,
.memberInfoWrap .formWrap .inputWrap input[type=password]:not(:placeholder-shown) + * {
  top: 0;
  background: #fff;
  z-index: 2;
}
.memberInfoWrap .formWrap .inputWrap .placeholder {
  position: absolute;
  z-index: 0;
  left: 1em;
  top: 50%;
  color: #999999;
  padding: 0 0.5em;
  transform: translate(0, -50%);
}
.memberInfoWrap .formWrap .optinWrap {
  margin-bottom: 1.3em;
}
.memberInfoWrap .formWrap .optinWrap .docs {
  margin-bottom: 1.3em;
}
.memberInfoWrap form {
  margin-bottom: 3em;
}
.memberInfoWrap form:last-of-type {
  margin-bottom: 0;
}
.memberInfoWrap form select,
.memberInfoWrap form textarea,
.memberInfoWrap form input {
  padding: 0.6em 0.6em;
  border-radius: 3px;
  border: 1px solid #cccccc;
}
.memberInfoWrap form input[type=text] {
  width: 100%;
}
.memberInfoWrap form > input[type=submit] {
  margin-top: 1em;
}
.memberInfoWrap form input[type=submit] {
  width: 100%;
  background: #ee7e22;
  color: #fff;
}
.memberInfoWrap form input[type=submit]:hover {
  background: #df5200;
}


/*
  レイアウト変更により追加(2024/12)
*/

.mb20 {
  margin-bottom: 20px;
}
hr.mb20 {
  background-color: #f5f1e3;
  height: 3px;
  border: none;
}

.couponList dl {
  display: flex;
  flex-wrap: wrap;
}

.couponList dt {
  width: 6.5em;
}
.couponList dd {
  width: calc(100% - 6.5em);
  margin-bottom: 0.6em;
}
.couponList p {
  color: #ee7a0d;
}
.couponList a {
  color: #ee7a0d;
}
.couponListLink {
  float: right;
}


.memberInfoWrap .formWrap .inputReadOnlyWrap {
  position: relative;
  margin-bottom: 1.3em;
}
.memberInfoWrap .formWrap .inputReadOnlyWrap input[type=text] {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 1em;
  background-color: transparent;
  border: none;
  outline: 1px solid #cccccc55;
}
.memberInfoWrap .formWrap .inputReadOnlyWrap input[type=text]:not(:-moz-placeholder-shown) + *,
.memberInfoWrap .formWrap .inputReadOnlyWrap input[type=password]:not(:-moz-placeholder-shown) + * {
  top: 0;
  background: #fff;
  z-index: 2;
}
.memberInfoWrap .formWrap .inputReadOnlyWrap input[type=text]:focus + *,
.memberInfoWrap .formWrap .inputReadOnlyWrap input[type=text]:not(:placeholder-shown) + *
{
  top: 0;
  background: #fff;
  z-index: 2;
}
.memberInfoWrap .formWrap .inputReadOnlyWrap .placeholder {
  position: absolute;
  z-index: 0;
  left: 1em;
  top: 50%;
  color: #999999;
  padding: 0 0.5em;
  transform: translate(0, -50%);
}