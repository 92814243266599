/* アクセシビリティ対策 */

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* ハンバーガーボタンのスタイル */

.hamburger {
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  height: 32px;
  outline: none;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: transform 0.3s, width 0.3s;
  width: 30px;
  z-index: 1005;
  /* メニューよりも上にする */
}

/* ハンバーガーメニュー三本線 */

.hamburger-line {
  background-color: #000;
  height: 3px;
  border-radius: 30px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transition: inherit;
  width: 100%;
}

.hamburger-line::before,
.hamburger-line::after {
  background-color: #000;
  content: "";
  display: block;
  height: 3px;
  border-radius: 30px;
  position: absolute;
  transition: inherit;
  width: 100%;
  z-index: 1001;
}

.hamburger-line::before {
  top: 10px;
}

.hamburger-line::after {
  top: 20px;
}

/* USER定義 */

.mainMenu {
  /* background-color: #fff; */
  /* position: fixed; */
  z-index: 1003;
  height: 100%;
  /* right: -100%; */
  top: 0;
  transition: right 0.3s ease-in-out;
  /* width: 60%; */
}

.mainMenu[aria-hidden="false"] {
  overflow-y: scroll;
  right: 0;
  transition: right 0.3s ease-in-out;
}

.mainMenu .nav-link {
  display: flex;
  background: #ee7a0d;
  color: #fff;
  padding: 1.3em 0.7em;
  border-bottom: 1px solid #fff;
  align-items: center;
}

.mainMenu ul {
  padding-left: 0;
}

.mainMenu li > img {
  max-width: 150px;
  padding: 1em 0.7em 0.7em 0.7em;
  list-style: none”;
}

.mainMenu a img {
  filter: brightness(0) invert(1);
  width: 23px;
  padding-right: 0.3em;
}
