/* $fontColor: #333333;
$mainColor: #ee7a0d;
$bgColor1: rgb(0 0 0 / 2.5%);
$mainColorDark: #0069c5;
$breakPoint1: 800px;
$breakPoint2: 500px;
 */

* {
  font-weight: normal;
  /* color: #333333; */
  margin: 0;
  padding: 0;
  max-width: 100%;
}

* {
  /* font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 16px; */
  line-height: 1.4;
  /* font-feature-settings: "palt" 1.1; */
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-size: 100%;
}

h1 {
  font-size: 130%;
}

*:focus {
  outline: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  vertical-align: bottom;
}

input[type=submit] {
  cursor: pointer;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #ee7a0d;
}

a:hover {
  color: #0069c5;
}