/**************************\
  Basic Modal Styles
\**************************/

.modalTopReview {
    background: linear-gradient(220.55deg, #FFED46 0%, #FF7EC7 100%);
    color: #fff;
    cursor: pointer;
    text-align: center;
    padding: 0.3em 0;
}

.modalTopDeactiveCookie {
    color: #777;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
}

.modal,
#modal {
    display: none;
}

.modal.is-open,
#modal.is-open {
    display: block;
}

.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__container {
    position: relative;
    background-color: #fff;
    padding: 15px;
    margin-right: 15px;
    margin-left: 15px;
    max-width: 10000px;
    max-height: 100vh;
    width: 100%;
    border-radius: 4px;
    overflow-y: auto;
    box-sizing: border-box;
}

.modal__container.single {
    background-color: #000;
}

.modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal__close {
    position: absolute;
    top: 0.3em;
    right: 0.3em;
    border: 0;
    cursor: pointer;
    background: #333;
    color: #fff;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    width: 3em;
    height: 3em;
    z-index: 10;
}

.modal__close:before {
    content: "\2715";
}

.modal__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 1.5;
    color: rgba(0, 0, 0, .8);
}

.modal__btn {
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #1B95E0;
    color: #fff;
    border-radius: 4px;
    border-style: none;
    border-width: 0;
    cursor: pointer;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
    line-height: 1.15;
    width: 100%;
    max-width: 320px;
    margin: 0;
    will-change: transform;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    transition: -webkit-transform .25s ease-out;
    transition: transform .25s ease-out;
    transition: transform .25s ease-out, -webkit-transform .25s ease-out;
}

.modal__btn:focus,
.modal__btn:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.modal__btn-primary {
    background-color: #00449e;
    color: #fff;
}


/**************************\
  Demo Animation Style
\**************************/

@keyframes mmfadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        transform: translateY(15%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-10%);
    }
}

.micromodal-slide {
    display: none;
}

.micromodal-slide.is-open {
    display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
    will-change: transform;
}


/**************************\
Common
\**************************/

.sec {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
}

.sec::after {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    content: '';
    background: rgba(0, 0, 0, .5);
}

/* .sec01 {
    background-image: url(img/bg-01.jpg);
}

.sec02 {
    background-image: url(img/bg-02.jpg);
} */

.wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.content {
    max-width: 100%;
    flex: 0 0 100%;
    position: relative;
    z-index: 2;
    text-align: center;
}

.heading {
    color: #fff;
    font-size: 28px;
    margin-bottom: 20px;
}

.modal__footer {
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0;
}

.modal__footer button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    color: #000;
    border-radius: 4px;
    border-width: 0;
    cursor: pointer;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
    max-width: 120px;
    line-height: 1.15;
    width: 100%;
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}




.tabs {
    margin-top: 50px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    margin: 0 auto;
}

/*タブのスタイル*/
.tab_item {
    width: calc(100%/2);
    height: 50px;
    border-bottom: 3px solid #5ab4bd;
    background-color: #d9d9d9;
    line-height: 50px;
    font-size: 16px;
    text-align: center;
    color: #565656;
    display: block;
    float: left;
    text-align: center;
    font-weight: bold;
    transition: all 0.2s ease;
}
.tab_item:hover {
    opacity: 0.75;
}

/*ラジオボタンを全て消す*/
input[name="tab_item"] {
    display: none;
}

/*タブ切り替えの中身のスタイル*/
.tab_content {
    display: none;
    padding: 10px;
    clear: both;
    overflow: hidden;
}


/*選択されているタブのコンテンツのみを表示*/
#all:checked ~ #all_content,
#programming:checked ~ #programming_content,
#design:checked ~ #design_content {
    display: block;
}

/*選択されているタブのスタイルを変える*/
.tabs input:checked + .tab_item {
    background-color: #5ab4bd;
    color: #fff;
}